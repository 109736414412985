import React, { useState } from "react";
import Map from "../../components/map/Map";
import Logo from "../../components/logo/Logo";
import Subtitle from "../../components/subtitle/Subtitle";
import Paragraph from "../../components/paragraph/Paragraph";
import {
  paragraph,
  subtitle,
  modalTitle,
  firstParagraph,
  secondParagraph,
} from "./data";
import styles from "./styles.module.css";
import Modal from "../../components/modal/Modal";

function App() {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className={styles.root}>
      <Map className={styles.map} />
      <div className={styles.gradient}></div>
      <div className={styles.topLayer}>
        <Logo />
        <div className={styles.section}>
          {paragraph.map((item) => (
            <Paragraph
              key={item.id}
              title={item.title}
              description={item.description}
              links={item.links}
            />
          ))}
          <Subtitle className={styles.subtitle} subtitle={subtitle} />
          <button className={styles.button} onClick={openModal}>
            Политика конфиденциальности
          </button>
          <Modal
            title={modalTitle}
            isOpen={modalIsOpen}
            onClose={closeModal}
          >
            <p className={styles.modalParagraph}>{firstParagraph}</p>
            <p className={styles.modalParagraph}>{secondParagraph}</p>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default App;
