export const paragraph = [
  {
    id: "0",
    title: "Команда разработки из Омска",
    description:
      "Разрабатываем сложные корпоративные сайты и помогаем бизнесу заявить\u00A0о\u00A0себе в интернете. Мы предлагаем гибкие решения в корпоративной сфере и имеем опыт работы в разработке сложных структур. В\u00A0отличие от универсальных агентств, мы ценим долгосрочные отношения с\u00A0перспективами на годы вперёд.",
  },
  {
    id: "1",
    title: "Наши клиенты",
    description:
      "Парфюм-лидер, АРТа\u00A0плюс, Металлокасса, Завод\u00A0тепловой\u00A0изоляции, Экопарк, Поставичок, Автошкола\u00A0«Перекрёсток», ZOKO\u00A0Лёгкие металлические\u00A0конструкции, Fast\u00A0Ping\u00A0Service, Стройсервис, Омскоил, Торговый\u00A0центр\u00A0«Каскад».",
  },
  {
    id: "2",
    title: "Контакты",
    description:
      "г.\u00A0Омск ​Булатова,\u00A0100, 2\u00A0кабинет; 4\u00A0этаж; офис\u00A0207",
    links: [
      { href: "tel:+79087996676", text: "+7\u00A0908\u00A0799-66-76" },
      { href: "mailto:hey@jvcr-solutions.ru", text: "hey@jvcr-solutions.ru" },
    ],
  },
];

export const subtitle = "из\u00A0Сибири с\u00A0любовью\u00A0";

export const modalTitle = "Согласие на обработку персональных данных";

export const firstParagraph =
  "Я, действуя свободно, своей волей и в своём интересе, продолжая работу на сайте (Домен) предоставляю настоящее согласие на обработку моих персональных данных (далее — Согласие) Обществу с ограниченной ответственностью _________ (далее — Оператор), на автоматизированную обработку моих Персональных данных (файлы cookie, сведения о действиях пользователя на сайте, сведения об оборудовании пользователя, дата и время сессии), в т. ч. с использованием метрических программ Яндекс.Метрика, Google Analytics с совершением действий: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, блокирование, удаление, уничтожение, передача (предоставление, доступ), партнёрам Оператора, предоставляющим сервис по указанным метрическим программам.";

export const secondParagraph =
  "Обработка Персональных данных осуществляется в целях улучшения работы сайта, определения предпочтений пользователя, сбора статистических данных. Настоящее согласие действует с момента его предоставления и в течение всего периода использования сайта. В случае отказа от обработки Персональных данных метрическими программами я проинформирован о необходимости прекратить использование сайта или отключить файлы cookie в настройках браузера.";
