import React, { FC } from "react";
import ModalWindow from "react-modal";
import styles from "./styles.module.css";

interface ModalProps {
  title: string;
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode | React.ReactNode[];
}

const Modal: FC<ModalProps> = (props: ModalProps) => {
  const { title, isOpen, onClose, children } = props;

  return (
    <ModalWindow
      isOpen={isOpen}
      overlayClassName={styles.overlay}
      className={styles.root}
      onRequestClose={onClose}
    >
      <div className={styles.content}>
        <button className={styles.closeButton} onClick={onClose} />
        <h3 className={styles.title}>{title}</h3>
        {children}
      </div>
    </ModalWindow>
  );
};

export default Modal;
