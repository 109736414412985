import React from "react";

interface Props {
  className?: string;
}

const BlueHeart: React.FC<Props> = (props) => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="15px"
      height="13px"
      viewBox="0 0 15 13"
    >
      <path
        d="M15 3.94967C15 1.77195 13.2392 0 11.0753 0C9.48721 0 8.11729 0.95407 7.49987 2.32178C6.88271 0.95407 5.51253 0 3.92519 0C1.76032 0 0 1.77195 0 3.94967C0 4.40905 0.0782815 4.84962 0.222724 5.26022C0.386862 5.72772 0.635595 6.15458 0.950994 6.52274C1.08559 6.68027 1.23154 6.82662 1.38937 6.96103L7.37614 12.9444C7.41301 12.9815 7.46099 13 7.50947 13C7.55795 13 7.60669 12.9815 7.64381 12.9444L13.8528 6.73744L13.8523 6.73668C14.2548 6.33091 14.5702 5.83825 14.7679 5.28867C14.9179 4.87046 15 4.41947 15 3.94967Z"
        fill="#37B7FF"
      />
    </svg>
  );
};

export default BlueHeart;
