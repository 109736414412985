import React, { FC } from "react";
import cn from "classnames";
import styles from "./styles.module.css";

interface MapProps {
  className?: string;
}

const Map: FC<MapProps> = ({ className = "" }) => {
  return <div className={cn(styles.root, className)} />;
};

export default Map;
