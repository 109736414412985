import React, { FC } from "react";
import cn from "classnames";
import styles from "./styles.module.css";

type Link = {
  href: string;
  text: string;
}
interface ParagraphProps {
  className?: string;
  title: string;
  description: string;
  links?: Link[];
}

const Paragraph: FC<ParagraphProps> = ({ className = "", title = "", description = "", links = [] }) => {
  return (
    <div className={cn(styles.root, className)}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      {links.map((item, i) => <a key={i} href={item.href} className={styles.description}>{item.text}</a>)}
    </div>
  );
};

export default Paragraph;
