import React, { FC } from "react";
import cn from "classnames";
import BlueHeart from "../../icons/BlueHeart";
import styles from "./styles.module.css";

interface SubtitleProps {
  className?: string;
  subtitle: string;
}

const Subtitle: FC<SubtitleProps> = ({ className = "", subtitle = "" }) => {
  return (
    <h3 className={cn(styles.root, className)}>
      {subtitle}
      <BlueHeart />
    </h3>
  );
};

export default Subtitle;
