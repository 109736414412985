import React, { FC } from "react";
import cn from "classnames";
import styles from "./styles.module.css";

interface LogoProps {
  className?: string;
}

const Logo: FC<LogoProps> = ({ className = "" }) => {
  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.logo} />
    </div>
  );
};

export default Logo;
